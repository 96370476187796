import React, { useContext } from "react"

import { Formik, Form } from "formik"

import Layout from "layout/Layout"
import Message from "elements/Message"
import Container from "layout/Container"
import ActionButtons from "elements/ActionButtons"

import { diagnosticSubsidyValidationSchema } from "../utils/validations"
import LOAFormPersonalDetails from "./LOAFormPersonalDetails"
import LOAFormLabCoordinator from "./LOAFormLabCoordinator"
import { AppContext } from "../../../context/AppContext"
import LOAFormMechanics from "./LOAFormMechanics"

const LOAForm = props => {
  const { state } = useContext(AppContext)

  return (
    <Layout
      title={props?.module?.title}
      subtitle={props?.module?.subtitle}
      seoTitle={props?.module?.seoTitle}
    >
      <Container isCentered>
        <Formik
          initialValues={{ labCoordinatorCode: "" }}
          onSubmit={props?.handleSubmit}
          validationSchema={diagnosticSubsidyValidationSchema}
        >
          {errors => (
            <Form>
              <LOAFormPersonalDetails
                enrollmentDetails={props?.enrollmentDetails}
                ticket={props?.ticket}
              />

              <LOAFormMechanics />
              <LOAFormLabCoordinator location={props?.location} />
              {props?.error && <Message color="danger">{props?.error}</Message>}
              <ActionButtons
                submit={{
                  label: "Submit",
                  loading: !!props?.loading?.form,
                  disabled: state?.documents?.length === 0,
                }}
              />
            </Form>
          )}
        </Formik>
      </Container>
    </Layout>
  )
}

export default LOAForm
