import { navigate } from "gatsby"
import React, { useCallback, useEffect, useState } from "react"
import {
  GATSBY_PHC_COORDINATOR_CODES,
  GATSBY_PATIENT_ENROLLMENT_FIELD,
  GATSBY_DIAGNOSTIC_SUBSIDY_TAG,
  GATSBY_PHC_COORDINATOR_CODE_FIELD,
} from "gatsby-env-variables"

import LOAForm from "./components/LOAForm"
import LOALoading from "./components/LOALoading"
import LOANotFound from "./components/LOANotFound"
import LOAAlreadyApproved from "./components/LOAAlreadyApproved"

import { zendeskApi } from "services/zendesk"
import { decrypt, encrypt } from "services/crypto"

const DiagnosticSubsidy = props => {
  const [loading, setLoading] = useState({
    ticket: true,
    form: false,
  })
  const [ticket, setTicket] = useState({})
  const [enrollmentDetails, setEnrollmentDetails] = useState({})
  const [error, setError] = useState("")
  const { location, pageContext } = props

  const { module, nextPath } = pageContext
  const urlParams = new URLSearchParams(location.search)
  const encryptedZendeskID = urlParams.get("e") || ""

  const getZendeskTicket = useCallback(async () => {
    console.log(encrypt({ data: "204086" }))
    let zendeskID = decrypt({
      encryptedData: encryptedZendeskID,
    })

    let ticketObject = {}
    if (zendeskID) {
      let zendeskTicket = await zendeskApi().get(`/search?query=${zendeskID}`)
      ticketObject = zendeskTicket?.data?.results[0]
      let enrollmentDetails = ticketObject?.custom_fields?.find(
        customField =>
          customField.id === parseInt(GATSBY_PATIENT_ENROLLMENT_FIELD)
      )?.value
      if (enrollmentDetails) {
        enrollmentDetails = JSON.parse(enrollmentDetails)
        setEnrollmentDetails(enrollmentDetails)
      }
    }

    setTicket(ticketObject)
    setLoading(previousLoading => ({
      ...previousLoading,
      ticket: false,
    }))
  }, [encryptedZendeskID])

  const handleSubmit = async values => {
    setError("")
    setLoading({ ...loading, form: true })
    if (GATSBY_PHC_COORDINATOR_CODES.includes(values?.labCoordinatorCode)) {
      await zendeskApi().put(`/tickets/${ticket.id}`, {
        ticket: {
          tags: [...ticket.tags, GATSBY_DIAGNOSTIC_SUBSIDY_TAG],
          custom_fields: [
            {
              id: parseInt(GATSBY_PHC_COORDINATOR_CODE_FIELD),
              value: values?.labCoordinatorCode,
            },
          ],
        },
      })
      navigate(nextPath)
    } else {
      setError("Invalid coordinator code.")
    }
    setLoading({ ...loading, form: false })
  }

  useEffect(() => {
    getZendeskTicket()
  }, [getZendeskTicket])

  if (!!loading?.ticket) return <LOALoading />

  if (ticket?.tags?.includes(GATSBY_DIAGNOSTIC_SUBSIDY_TAG))
    return <LOAAlreadyApproved />
  if (!ticket?.id) return <LOANotFound />

  return (
    <LOAForm
      error={error}
      ticket={ticket}
      module={module}
      loading={loading}
      location={location}
      handleSubmit={handleSubmit}
      enrollmentDetails={enrollmentDetails}
    />
  )
}

export default DiagnosticSubsidy
